var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-1",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left pr-2",staticStyle:{"margin-left":"0","width":"15%","font-size":"13px","font-weight":"normal"}},[_c('DanMenu',{attrs:{"name":_vm.selectedBookName,"menu-items":_vm.bookOptions},on:{"dan-menu-click":_vm.onMenuItemClick}})],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-right":"0","width":"85%"}},[_c('span',[(_vm.fetchingTime)?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Last Fetched at: "+_vm._s(_vm.fetchingTime))]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"default","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.periodOptions.find( (option) => option.value === _vm.selectedPeriod ).key)+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.periodOptions),function(item){return _c('v-list-item',{key:item.key,on:{"click":() => {
                                            _vm.selectedPeriod = item.value;
                                        }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.key))])],1)}),1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-0 flex-grow-1",staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.netPositionTableHeaders,"items":_vm.netPositionTableData,"header-props":{ sortIcon: null },"item-key":"symbol","items-per-page":30,"footer-props":{
                        itemsPerPageOptions: [10, 20, 30],
                        showFirstLastPage: false,
                        'items-per-page-text': '',
                    },"sortBy":"netPositions_change","fixed-header":"","height":"350","customSort":_vm.customSort,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.netPositions_change",fn:function({ item }){return [(item.netPositions_change > 0)?_c('span',{staticStyle:{"color":"#27ae60"}},[_vm._v(_vm._s(item.netPositions_change))]):(item.netPositions_change < 0)?_c('span',{staticStyle:{"color":"#f44336"}},[_vm._v(_vm._s(item.netPositions_change))]):_c('span',[_vm._v(_vm._s(item.netPositions_change))])]}}])})],1)],1)],1),_c('v-col',{staticClass:"pr-2 pt-2",attrs:{"cols":"8"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-1",staticStyle:{"border-bottom":"1px solid #404040"}},[_vm._v(" KPI Information ")]),_c('v-row',{staticClass:"pa-4 pb"},_vm._l((_vm.kpiDetailHeaders),function(item){return _c('v-col',{staticClass:"pa-2",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"text text--primary"},[_vm._v(" "+_vm._s(_vm.kpiDetail[item.key])+" ")])])],1)],1)}),1)],1)],1),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-1",staticStyle:{"border-bottom":"1px solid #404040"}},[_vm._v(" Comment ")]),_c('v-textarea',{attrs:{"height":"335","filled":"","label":"","auto-grow":"","hide-details":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }