<template>
    <v-row no-gutters class="flex-shrink-0 flex-grow-0">
        <v-col cols="12">
            <v-card class="d-flex flex-column" outlined>
                <v-card-title
                    style="border-bottom: 1px solid #404040"
                    class="pt-1"
                >
                    <div
                        class="text-left pr-2"
                        style="
                            margin-left: 0;
                            width: 15%;
                            font-size: 13px;
                            font-weight: normal;
                        "
                    >
                        <DanMenu
                            :name="selectedBookName"
                            :menu-items="bookOptions"
                            @dan-menu-click="onMenuItemClick"
                        />
                    </div>
                    <div class="text-right" style="margin-right: 0; width: 85%">
                        <span>
                            <span style="font-size: 14px" v-if="fetchingTime"
                                >Last Fetched at: {{ fetchingTime }}</span
                            >
                            <v-menu offset-y dense>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        color="default"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-1"
                                    >
                                        {{
                                            periodOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    selectedPeriod
                                            ).key
                                        }}
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        v-for="item in periodOptions"
                                        :key="item.key"
                                        @click="
                                            () => {
                                                selectedPeriod = item.value;
                                            }
                                        "
                                    >
                                        <v-list-item-title>{{
                                            item.key
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </span>
                    </div>
                    <v-data-table
                        dense
                        :headers="netPositionTableHeaders"
                        :items="netPositionTableData"
                        style="width: 100%"
                        :header-props="{ sortIcon: null }"
                        item-key="symbol"
                        class="elevation-0 flex-grow-1"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [10, 20, 30],
                            showFirstLastPage: false,
                            'items-per-page-text': '',
                        }"
                        sortBy="netPositions_change"
                        fixed-header
                        height="350"
                        :customSort="customSort"
                        :loading="loading"
                    >
                        <template v-slot:item.netPositions_change="{ item }">
                            <span
                                v-if="item.netPositions_change > 0"
                                style="color: #27ae60"
                                >{{ item.netPositions_change }}</span
                            >
                            <span
                                v-else-if="item.netPositions_change < 0"
                                style="color: #f44336"
                                >{{ item.netPositions_change }}</span
                            >
                            <span v-else>{{ item.netPositions_change }}</span>
                        </template>
                    </v-data-table>
                </v-card-title>
            </v-card>
        </v-col>
        <v-col cols="8" class="pr-2 pt-2">
            <v-card class="d-flex flex-column" outlined>
                <v-card-title
                    style="border-bottom: 1px solid #404040"
                    class="pt-1"
                >
                    KPI Information
                </v-card-title>
                <v-row class="pa-4 pb">
                    <v-col cols="4" v-for="item in kpiDetailHeaders" class="pa-2">
                        <v-card class="mx-auto" max-width="344">
                            <v-card-text>
                                <div>
                                    {{ item.name }}
                                </div>
                                <p class="text text--primary">
                                    {{ kpiDetail[item.key] }}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="4" class="pt-2">
            <v-card class="d-flex flex-column" outlined>
                <v-card-title
                    style="border-bottom: 1px solid #404040"
                    class="pt-1"
                >
                    Comment
                </v-card-title>
                <v-textarea
                    height="335"
                    filled
                    label=""
                    auto-grow
                    hide-details
                ></v-textarea>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { helper } from "@components/mixins/helper";
import { getBooksPk } from "@services/books";
import DanMenu from "@views/home/ComponentDanMenu";
import common from "@assets/js/common";
import { fetchChangeSummaryData } from "@services/change-summary";

export default {
    mixins: [helper],
    components: {
        DanMenu,
    },
    data() {
        return {
            bookOptions: [],
            selectedBook: "",
            netPositionTableHeaders: [
                { text: "Symbol", value: "symbolName" },
                {
                    text: "Net Position Last",
                    value: "netPositions_prev",
                    align: "end",
                },
                {
                    text: "Net Position New",
                    value: "netPositions_curr",
                    align: "end",
                },
                {
                    text: "Net Position Changed",
                    value: "netPositions_change",
                    align: "end",
                },
                {
                    text: "Net PNL Changed",
                    value: "netPNL_change",
                    align: "end",
                },
            ],
            periodOptions: [
                { key: "5min", value: 5 },
                { key: "10min", value: 10 },
                { key: "15min", value: 15 },
                { key: "30min", value: 30 },
                { key: "1hr", value: 60 },
            ],
            kpiDetailHeaders: [
                { name: "Unrealized Last", key: "unrealized_last" },
                { name: "Unrealized New", key: "unrealized_new" },
                { name: "Changed", key: "unrealized_changed" },
                { name: "Realized Last", key: "realized_last" },
                { name: "Realized New", key: "realized_new" },
                { name: "Changed", key: "realized_changed" },
                { name: "Net Last", key: "net_last" },
                { name: "Net New ", key: "net_new" },
                { name: "Changed", key: "net_changed" },
            ],
            selectedPeriod: 60,
            netPositionTableData: [],
            fetchingTime: null,
            kpiDetail: {},
            loading: false,
        };
    },
    computed: {
        selectedBookName() {
            return `${this.selectedBook} BOOK`;
        },
    },
    watch: {
        selectedPeriod() {
            this.fetchData();
        },
        selectedBook() {
            this.fetchData();
        },
    },
    methods: {
        onMenuItemClick(item) {
            this.selectedBook = item;
        },
        fetchData() {
            this.loading = true;
            const mt4Time = common.getMT4TimeStringWithTimeDelta(1);
            const prevMt4Time = common.getMT4TimeStringWithTimeDelta(
                this.selectedPeriod + 1
            );
            this.fetchingTime = common.getMT4TimeString();
            const tableParam = {
                book: this.selectedBook,
                currTime: mt4Time,
                prevTime: prevMt4Time,
            };
            fetchChangeSummaryData(tableParam)
                .then(({ data }) => {
                    for (const key of Object.keys(data.desc)) {
                        data.desc[key] = data?.desc[key]?.toFixed(2);
                    }
                    this.kpiDetail = data.desc;
                    this.netPositionTableData = data.table;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (
                    sortBy[0] === "netPositions_prev" ||
                    sortBy[0] === "netPositions_curr" ||
                    sortBy[0] === "netPositions_change" ||
                    sortBy[0] === "netPNL_change"
                ) {
                    if (sortDesc[0]) {
                        return Math.abs(a[sortBy]) - Math.abs(b[sortBy]);
                    }
                    if (!sortDesc[0]) {
                        return Math.abs(b[sortBy]) - Math.abs(a[sortBy]);
                    }
                } else {
                    if (!sortDesc[0]) {
                        return a[sortBy] < b[sortBy] ? -1 : 1;
                    } else {
                        return b[sortBy] < a[sortBy] ? -1 : 1;
                    }
                }
            });
            return items;
        },
    },
    created() {
        getBooksPk().then(({ data }) => {
            const result = [];
            for (const item of data) {
                if (!!item.children) {
                    result.push({
                        name: item.book_name,
                        parent: null,
                        children: item.children,
                    });
                    for (const child of item.children) {
                        result.push({
                            name: child.book_name,
                            parent: item.book_name,
                            children: child.children || null,
                        });
                    }
                } else {
                    result.push({
                        name: item.book_name,
                        parent: null,
                        children: null,
                    });
                }
            }
            this.bookOptions = data;
            if (!data.find((item) => item.book_name === "B")) {
                this.selectedBook = data[0].book_name;
            } else {
                this.selectedBook = "B";
            }
        });
    },
    destroyed() {},
};
</script>

<style></style>
